<template>
  <div class="page">
    <Login ref="login" :callback="init" mode="GST" />
    <div class="search">
      <van-row>
        <van-col span="3" @click="back()"
          ><div class="back-btn">
            <van-icon name="arrow-left" />返回
          </div></van-col
        >
        <van-col span="21">
          <van-search
            v-model="keyword"
            placeholder="点击搜索签证"
            @click="search"
            readonly
            ref="keyword"
          ></van-search>
        </van-col>
      </van-row>
    </div>
    <van-swipe class="banner" :autoplay="6000" indicator-color="white">
      <van-swipe-item>
        <img
          class="img"
          :src="require('../../../../assets/images/visa/ban01.jpg')"
        />
      </van-swipe-item>
    </van-swipe>
    <div class="classify">
      <van-grid :column-num="4">
        <van-grid-item
          :text="classify.name"
          v-for="classify in classifys"
          :key="classify.code"
          @click="classifySearch(classify.name)"
        >
          <template #icon>
            <img
              :src="require('../../../../assets/images/visa/' + classify.icon)"
              class="icon"
            />
          </template>
        </van-grid-item>
      </van-grid>
    </div>
    <div class="list">
      <van-row class="title">
        <van-col span="12" class="text"
          ><van-icon name="notes-o" /> 热门签证</van-col
        ><van-col span="12" class="btn color-m"
          ><div @click="search">全部签证 <van-icon name="arrow" /></div
        ></van-col>
      </van-row>
      <van-list
        :finished="finished"
        finished-text="没有更多了"
        offset="0"
        :immediate-check="false"
        @load="retrieveHotProductPage"
      >
        <template #default>
          <van-row class="products">
            <van-col
              @click="detail(product.code)"
              v-for="product in productList"
              :key="product.code"
              span="12"
            >
              <div class="product">
                <div class="lable">
                  <van-tag :color="COLOR_S1">热门</van-tag>
                </div>
                <div>
                  <van-image class="image" :src="product.imagePath"></van-image>
                </div>
                <div class="name text-short">{{ product.name }}</div>
                <van-row class="row">
                  <van-col span="12" class="type">
                    <van-tag :color="COLOR_M">{{
                      product.country
                    }}</van-tag></van-col
                  >
                  <van-col span="12" class="price color-s2">
                    <van-tag
                      :color="COLOR_S1"
                      v-if="product.interviewType === 'RDM'"
                      >抽取</van-tag
                    ><van-tag
                      :color="COLOR_S1"
                      v-if="product.interviewType === 'NOT'"
                      >无面试</van-tag
                    ><van-tag
                      :color="COLOR_S1"
                      v-if="product.interviewType === 'MST'"
                      >需面试</van-tag
                    ></van-col
                  >
                </van-row>
                <van-row class="row">
                  <van-col span="24" class="price color-s1">
                    <van-icon name="cash-back-record" /><span
                      >{{ product.priceNew }}元</span
                    >&nbsp;&nbsp;<span class="old"
                      >{{ product.priceOld }}元</span
                    >
                  </van-col>
                </van-row>
                <!-- <div class="cert">
                <van-tag plain :color="COLOR_S1">{{ task.cert }}</van-tag>
              </div> -->
              </div>
            </van-col>
          </van-row>
          <van-row v-if="productList.length <= 0">
            <van-col span="24" class="no-record">
              <van-image
                width="103"
                height="103"
                :src="require('../../../../assets/images/home/no-record.png')"
              ></van-image>
            </van-col>
          </van-row>
        </template>
      </van-list>
    </div>
    <Clue ref="clue" />
    <Call
      ref="call"
      win-show="false"
      entry-show="true"
      clue-model="VISA_CONSULT_ONLINE"
    />
    <Loading :show="loadingShow" />
    <Share ref="share" />
    <LogVisit ref="logVisit" module-type="VSA" module="VSA_HOME_INDEX" />
  </div>
</template>
<script>
import Index from './Index.js'
export default {
  ...Index
}
</script>
<style lang="less" scoped>
@import './Index.less';
</style>
